import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import UnderConstruction from '../../images/under_construction.svg';
import withTheme from '../WithTheme';

const { GATSBY_APP_HOST } = process.env;

const Component404 = () => {
    return(
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Typography
                color="white"
                variant="h4"
            >
                Ups!
            </Typography>
            <Typography
                color="white"
                variant="subtitle1"
                align="center"
            >
                El Zone al que intentas acceder no existe
            </Typography>
            <img
                style={{
                    marginTop: 20
                }}
                width={ 350 }
                src={ UnderConstruction }
            />
            <Typography
                color="white"
                variant="subtitle1"
            >
                ¿Por qué no lo creas antes de que te lo ganen?
            </Typography>
            <Button
                variant="contained"
                sx={{
                    mt: 2,
                    textTransform: 'none'
                }}
                onClick={ () => {
                    window.open( GATSBY_APP_HOST )
                }}
            >
                ¡Comienza gratis aquí!
            </Button>
        </Box>
    )
}


export default withTheme(Component404);