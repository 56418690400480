import * as React from "react"
import Component404 from "../components/common/Component404"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Component404/>
  </Layout>
)

export default NotFoundPage
